<template>
	<div class="contianer">
		<div class="citylist">
			<div class="chengshi singlebox">
				<div class="tabcate">城&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;市：</div>
				<div class="selectitem">
					<div @click="changeShi(item)" :class="{ 'cityitem': true, 'current': item.checked }" :key="item.id"
						v-for="item in all_city">{{
							item.city
						}}</div>
				</div>
			</div>
			<div class="quxian singlebox">
				<div class="tabcate">区&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;县：</div>
				<div class="selectitem">
					<div @click="changeQu(item)" :class="{ 'cityitem': true, 'current': item.checked }" :key="item.id"
						v-for="item in all_district">{{
							item.city
						}}</div>
				</div>
			</div>
			<div class="xingzhi singlebox">
				<div class="tabcate">项目性质：</div>
				<div class="selectitem">
					<div @click="changeType(item)" :class="{ 'cityitem': true, 'current': item.checked }" :key="item.id"
						v-for="item in property">{{
							item.name
						}}</div>
				</div>
			</div>
		</div>
		<div class="listcon">
			<div class="search">
				<div class="title">查询结果</div>
				<div class="rightSearch">
					<div class="inputbox">
						<input v-model="search" type="text" placeholder="请输入关键字" />
					</div>
					<div @click="searchcon" class="btn"></div>
				</div>
			</div>
			<div class="list">
				<div v-for="item in list" :key="item.id" class="listitem" @click="todetail(item)">
					<img class="cover" :src="item.file_url" alt="">
					<div class="titlebox">
						<div class="title">{{ item.ssq }}</div>
						<div class="date">{{ item.times }}</div>
					</div>
					<div class="locationbox">
						<div class="location">{{ item.location }}</div>
						<div :class="['typebtn', 'typebtn' + item.usecate]"> {{ item.usecatename }} </div>
					</div>
				</div>


			</div>
			<div class="page">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page"
					:page-size="size" background layout="prev, pager, next" :total="total">
				</el-pagination>
			</div>

		</div>
	</div>
</template>

<script>

import { hangpaiInit, hangpaiDistrict, hangpaiList } from '@/api/website/hangpai.js'

export default {
	name: 'PcWebsiteHangpai',
	computed: {
		site: {
			get() {
				return this.$store.state.site
			},
			set(v) {
				this.$store.dispatch('site', v);
			}
		}
	},

	data() {
		return {
			search: '',
			initdata: {},
			page: 1,
			size: 20,
			total: null,
			all_city: [],
			all_district: [],
			property: [],
			list: [],
			city_id: 0,
			usecate: 0,
			district_id: 0
		};
	},

	mounted() {
	
		var that = this
		
		

		this.init().then(res=>{
			that.initpage()


			if(that.$route.query.city_id){
				that.city_id = that.$route.query.city_id

				that.all_city.map(city => {
					if (city.id === Number(that.$route.query.city_id)){
						city.checked = true
						that.city_id = city.id
						that.district_id = 0
						that.getQu(city.id)
					} else {
						city.checked = false
					}
					return city
				})

				
			}




			if(that.$route.query.usecate){

				that.property.map(city => {
						if (city.id === Number(that.$route.query.usecate)) {
							city.checked = true
							that.usecate = city.id
						} else {
							city.checked = false
						}
						return city
					})
				}



		
			
			
		})
	

	
	},
	watch: {
		$route: {
			handler() {
				this.initpage()
				
			},
			deep: true,
			immediate:true
		}
	},

	methods: {
		initpage() {
			this.page = Number(this.$route.query.page) || 1
			if(this.$route.query.city_id){
				this.city_id = this.$route.query.city_id
				
			}
			if(this.$route.query.district_id){
				this.district_id = this.$route.query.district_id
			}
			if(this.$route.query.usecate){
				this.usecate = this.$route.query.usecate
			}
			
		
			this.conlist()
		},

		todetail(item) {
			const tourl = '/' + this.site.path + '/detail/hangpai/' + item.id
			// this.$router.push(tourl)
			let { href } = this.$router.resolve({ path:tourl })
			window.open(href, '_blank')
		},

		changeShi(item) {
			this.all_city.map(city => {
				if (city.id === item.id) {
					city.checked = true
					this.city_id = city.id
					this.district_id = 0
					this.getQu(city.id)
				} else {
					city.checked = false
				}
				return city
			})
			this.page = 1
			const tourl = '/' + this.site.path + '/list/hangpai?page='+this.page+'&city_id='+this.city_id+'&district_id='+this.district_id+'&usecate='+this.usecate
			this.$router.push(tourl)
			// this.conlist()
		},

		changeQu(item) {
		

			this.all_district.map(city => {
				if (city.id === item.id) {
					city.checked = true
					this.district_id = city.id
				} else {
					city.checked = false
				}
				return city
			})
			this.page = 1
			const tourl = '/' + this.site.path + '/list/hangpai?page='+this.page+'&city_id='+this.city_id+'&district_id='+this.district_id+'&usecate='+this.usecate
			this.$router.push(tourl)

		},

		changeType(item) {
			this.property.map(city => {
				if (city.id === item.id) {
					city.checked = true
					this.usecate = city.id
				} else {
					city.checked = false
				}
				return city
			})

			this.page = 1
			const tourl = '/' + this.site.path + '/list/hangpai?page='+this.page+'&city_id='+this.city_id+'&district_id='+this.district_id+'&usecate='+this.usecate
			this.$router.push(tourl)
		},

		getQu(id) {
			// return	new Promise(function(resolve,reject){
				hangpaiDistrict({ city_id: id }).then(res => {
					this.all_district = res.data
					var that = this

					if(that.$route.query.district_id){
						that.all_district.map(city => {
								if (city.id === Number(that.$route.query.district_id)) {
									city.checked = true
									that.district_id = city.id
								} else {
									city.checked = false
								}
								return city
							})
						}

					// return resolve(res)
				})
			// })
		},

		init() {
			var that = this
		return	new Promise(function(resolve,reject){
			
			 	hangpaiInit().then(res => {
					that.all_city = res.data.all_city
					that.all_district = res.data.all_district
					that.property = res.data.property
					that.list = res.data.list
					that.total = res.data.total


					
					return resolve(res)
				})
				
			})
		
		},

		searchcon() {
			if (Number(this.page) !== 1) {
				this.page = 1
				const tourl = '/' + this.site.path + '/list/hangpai?page=1'
				this.$router.push(tourl)
			} else {
				this.conlist()
			}


		},


		conlist() {
			const params = { page: this.page, size: 16, city_id: this.city_id, district_id: this.district_id, usecate: this.usecate }
			if (this.search !== '') {
				params.search = this.search
			}
			hangpaiList(params).then(res => {
				this.list = res.data.list
				this.total = res.data.total
			})
		},

		handleSizeChange() {

		},

		handleCurrentChange(val) {
	
			this.page = +val

			const tourl = '/' + this.site.path + '/list/hangpai?page='+this.page+'&city_id='+this.city_id+'&district_id='+this.district_id+'&usecate='+this.usecate
				this.$router.push(tourl)

	
		},
	},
};
</script>

<style lang="scss" scoped>
.contianer {
	background: #F0F3F8;
}

.list {
	height: 940px;
	display: flex;
	flex-wrap: wrap;
	margin-left: 20px;
	align-items: flex-start;
	align-content: flex-start;

	.listitem {
		margin-right: 14px;
		width: 279px;
		height: 233px;
		display: flex;
		flex-direction: column;
		cursor: pointer;

		.cover {
			width: 279px;
			height: 160px;
		}

		.titlebox {

			height: 20px;
			width: 279px;
			margin-top: 10px;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.title {
				flex: 1;
				height: 20px;
				font-size: 14px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #000000;
				line-height: 20px;
				text-align: left;

			}

			.date {
				width: 80px;
				height: 17px;
				font-size: 12px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #A0A0A0;
				line-height: 17px;
			}
		}

		.locationbox {

			height: 20px;
			width: 279px;
			margin-top: 5px;
			display: flex;
			justify-content: space-between;
			align-items: center;


			.location {
				flex: 1;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				height: 17px;
				font-size: 12px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #565656;
				line-height: 17px;
				text-align: left;
			}

			.typebtn {
				width: 36px;
				height: 20px;
				line-height: 20px;
				border-radius: 2px;
			}

			.typebtn1 {
				background: #FFFF00;
			}

			.typebtn2 {
				background: #FCBB81;
			}

			.typebtn3 {
				background: #E40000;
			}

			.typebtn4 {
				background: #E74FD6;
			}
		}


	}
}


.citylist {
	width: 1200px;
	min-height: 134px;
	background: #FFFFFF;
	display: flex;
	flex-direction: column;
	padding: 20px;
	box-sizing: border-box;

	.singlebox {
		width: 100%;
		display: flex;

		.tabcate {
			width: 80px;
			height: 22px;
			font-size: 16px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #000000;
			line-height: 22px;
			text-align: justify;
			text-justify: inter-ideograph;
		}

		.selectitem {
			display: flex;
			flex-wrap: wrap;



			.current {
				color: #00A3E4
			}
		}

	}


}

.cityitem {
	margin-bottom: 10px;
	margin-right: 20px;
	margin-left: 10px;
	height: 22px;
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #000000;
	line-height: 22px;
	cursor: pointer;

}

.listcon {
	margin-top: 10px;
	background: #fff;
	box-sizing: border-box;

	.search {
		width: 1200px;
		height: 62px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		box-sizing: border-box;

		.title {
			padding-left: 20px;
			width: 64px;
			height: 22px;
			font-size: 16px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #000000;
			line-height: 22px;

		}

		.rightSearch {
			margin-right: 20px;
			display: flex;

			.inputbox {
				background: #F2F2F2;

				input {
					outline: none;
					border: none;
					height: 28px;
					background: #F2F2F2;
					font-size: 12px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #272121c8;
					padding-left: 10px;
				}
			}

			.btn {
				width: 56px;
				height: 30px;
				display: flex;
				justify-content: center;
				align-items: center;
				background-size: 10px 10px;
				cursor: pointer;
				background: url('/pic/searchwhite.png') left 17px top 6px no-repeat #00A3E4;

				background-size: 20px 20px;
				cursor: pointer;

			}
		}
	}

}

.page {

	margin-top: 30px;
	padding-bottom: 30px;
}
</style>